import React, { useState } from "react";
import BlushLogo from "../../assets/blush.webp";
import "./Navbar.css";

const Navbar = () => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const handleMenuClick = () => {
    setIsMenuClicked(!isMenuClicked);
  };

  return (
    <nav>
      <a id="home" href="/">
        <img src={BlushLogo} alt="nav logo" height={50} width={50} />
        <p>gorge blush</p>
      </a>
      <div>
        <ul
          id="navbar"
          className={isMenuClicked ? "#navbar active" : "#navbar"}
        >
          <li onClick={handleMenuClick}>
            <a href="#abawt">abawt</a>
          </li>
          <li onClick={handleMenuClick}>
            <a href="#tokenomikz">tokenomikz</a>
          </li>
          <li onClick={handleMenuClick}>
            <a href="#motibaetion">motibaetion</a>
          </li>
          <li onClick={handleMenuClick}>
            <a href="#diskclaimer">diskclaimer</a>
          </li>
        </ul>
      </div>
      <div id="mobile" onClick={handleMenuClick}>
        <i
          id="bar"
          className={isMenuClicked ? "fas fa-times" : "fas fa-bars"}
        ></i>
      </div>
    </nav>
  );
};

export default Navbar;
