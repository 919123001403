import styled from "styled-components";

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10rem;
  height: 80vh;
  font-size: 24px;
  text-align: center;
  background-color: #003a6b;
  color: white;

  @media screen and (max-width: 1059px) {
    flex-direction: column-reverse;
    height: 90vh;
  }

  @media screen and (max-width: 762px) {
    padding: 0 5rem;
  }

  @media screen and (max-width: 525px) {
    height: auto;
    padding-bottom: 30px;
  }
`;

export const HeroImg = styled.img`
  width: 500px;
  height: 500px;

  @media screen and (max-width: 1221px) {
    width: 400px;
    height: 400px;
  }

  @media screen and (max-width: 1059px) {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 30px;
  }

  @media screen and (max-width: 762px) {
    font-size: 25px;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const SocialLink = styled.a`
  text-decoration: none;
  color: #fff;
  font-size: 50px;
  cursor: pointer;

  @media screen and (max-width: 762px) {
    font-size: 30px;
  }
`;

export const SocialImg = styled.img`
  height: 50px;
  width: 50px;
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
  z-index: -100;

  @media screen and (max-width: 762px) {
    height: 30px;
    width: 30px;
  }
`;

export const ContractAddress = styled.h1`
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;
  white-space: pre-wrap;

  @media screen and (max-width: 1221px) {
    font-size: 20px;
  }

  @media screen and (max-width: 762px) {
    font-size: 15px;
  }
`;

export const SubTitle = styled.h1`
  font-size: 30px;
  font-weight: 600;

  @media screen and (max-width: 1221px) {
    font-size: 20px;
  }

  @media screen and (max-width: 762px) {
    font-size: 20px;
  }
`;
