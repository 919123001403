import React from "react";
import {
  DisclaimerContainer,
  Header,
  SubTitle,
  Title,
} from "./Disclaimer.styles";

const Disclaimer = () => {
  return (
    <DisclaimerContainer id="diskclaimer">
      <Header>diskclaimer</Header>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Title>
        gorge blush ($BLUSH) has no association with Former US President Goerge
        Bush. this token is intended for entertainment purposes only. $BLUSH is
        a meme coin with no intrinsic value or expectation of financial return.
        always DYOR.
      </Title>
      <br />
      <SubTitle>all rights reserved.</SubTitle>
    </DisclaimerContainer>
  );
};

export default Disclaimer;
