import React from "react";

import {
  TokenomicsContainer,
  Description,
  Title,
  Header,
  TokenomicsImg,
  SubTitle,
} from "./Tokenomics.styles";
import TokenomicsLogo from "../../../../assets/tokenomics.webp";

const Tokenomics = () => {
  return (
    <div>
      <TokenomicsContainer id="tokenomikz">
        <Header>tokenomikz</Header>
        <TokenomicsImg alt="tokenomics logo" src={TokenomicsLogo} />
        <Description>
          <br />
          <SubTitle>** total circulation supply **</SubTitle>
          <Title>910,000,000</Title>
          <br />
          <Title>
            to commemorate d victimz of rugpulls and to strengthen d community,
            we will do airdrops 4 evry 9 days for 11 consekutiv strayt, d
            airdrop will b based on da price to avoid dumpin' n we all happy.
            holders of $BLUSH will b eligible, olrayt?
          </Title>
          <SubTitle>** we luv u dats why we do diz **</SubTitle>
        </Description>
      </TokenomicsContainer>
    </div>
  );
};

export default Tokenomics;
