import React from "react";
import { enqueueSnackbar } from "notistack";
import { FaSquareXTwitter, FaTelegram } from "react-icons/fa6";

import {
  HeroContainer,
  Description,
  Title,
  SocialContainer,
  SocialLink,
  SocialImg,
  HeroImg,
  ContractAddress,
  SubTitle,
} from "./Hero.styles";
import LeftLogo from "../../../../assets/blush.webp";
import CoingeckoLogo from "../../../../assets/coingecko.webp";
import CMCLogo from "../../../../assets/cmc.webp";
import DexscreenerLogo from "../../../../assets/dexscreener.webp";
import DextoolsLogo from "../../../../assets/dextools.webp";
import SolscanLogo from "../../../../assets/solscan.webp";

const Hero = () => {
  const onCMCClick = () => {
    enqueueSnackbar("Plz w8 as we r doin cmc fast-track to list.", {
      autoHideDuration: 3000,
      variant: "info",
    });
  };

  const onDextoolsClick = () => {
    enqueueSnackbar("Plz w8 as we r procezzing dis.", {
      autoHideDuration: 3000,
      variant: "info",
    });
  };

  const onCoingeckoClick = () => {
    enqueueSnackbar(
      "Plz w8 as we r submittin our info to d coingecko gud gois.",
      {
        autoHideDuration: 3000,
        variant: "info",
      }
    );
  };

  const onContractAddressClick = () => {
    navigator.clipboard.writeText(
      "72x9gtpSJw6Gvup8uRbrbAmaSP96TdCFGQ99TgAs5y24"
    );
    enqueueSnackbar("Succezzfully copied the contract addrezz!", {
      autoHideDuration: 3000,
      variant: "success",
    });
  };

  return (
    <HeroContainer id="abawt">
      <Description>
        <Title>
          "too meni too gud to b tru tokens r getting owt of d buzinezz. too
          meni dev aren't able to praktiz tier luv w/ invezthors all across diz
          contry. let's break d cycle!"
        </Title>
        <p>- pormer press of US, gorge blush</p>
        <SocialContainer>
          <SocialLink
            href="https://twitter.com/gorgeblush"
            rel="noopenner noreferrer"
            target="_blank"
          >
            <FaSquareXTwitter />
          </SocialLink>
          <SocialLink
            href="https://t.me/gorgeblush2drescue"
            rel="noopenner noreferrer"
            target="_blank"
          >
            <FaTelegram />
          </SocialLink>
          <SocialLink
            href="https://solscan.io/token/72x9gtpSJw6Gvup8uRbrbAmaSP96TdCFGQ99TgAs5y24"
            rel="noopenner noreferrer"
            target="_blank"
          >
            <SocialImg src={SolscanLogo} alt="solscan" />
          </SocialLink>
          <SocialLink
            href="https://www.dextools.io/app/en/solana/pair-explorer/Gi9KM1wKKqcpjbU7FRoH7ZuXE3Da9HJSPTXHXeYhZxbm"
            rel="noopenner noreferrer"
            target="_blank"
            onClick={onDextoolsClick}
          >
            <SocialImg src={DextoolsLogo} alt="dextools" />
          </SocialLink>
          <SocialLink
            href="https://dexscreener.com/solana/72x9gtpSJw6Gvup8uRbrbAmaSP96TdCFGQ99TgAs5y24"
            rel="noopenner noreferrer"
            target="_blank"
          >
            <SocialImg src={DexscreenerLogo} alt="dexscreener" />
          </SocialLink>
          <SocialLink onClick={onCMCClick}>
            <SocialImg src={CMCLogo} alt="coinmarketcap" />
          </SocialLink>
          <SocialLink onClick={onCoingeckoClick}>
            <SocialImg src={CoingeckoLogo} alt="coingecko" />
          </SocialLink>
        </SocialContainer>
        <SubTitle>** click contract addrezz below to copy **</SubTitle>
        <ContractAddress onClick={onContractAddressClick}>
          72x9gtpSJw6Gvup8uRbrbAmaSP96TdCFGQ99TgAs5y24
        </ContractAddress>
      </Description>
      <HeroImg alt="hero logo" src={LeftLogo} />
    </HeroContainer>
  );
};

export default Hero;
