import React from "react";

import {
  Header,
  MotivationContainer,
  MotivationImg,
  Title,
} from "./Motivation.styles";
import FirstImg from "../../../../assets/blush-tremp.webp";
import SecondImg from "../../../../assets/obema-blush.webp";

const Motivation = () => {
  return (
    <MotivationContainer id="motibaetion">
      <Header>motibaetion</Header>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Title>obema gathered d ppl who were affected and destroyed</Title>
      <MotivationImg src={SecondImg} alt="obema blush" />
      <Title>
        and then d US pressidentz hav conduckted a meeting n seek halp to d
        pormer press
      </Title>
      <MotivationImg src={FirstImg} alt="blush tremp" />
    </MotivationContainer>
  );
};

export default Motivation;
