import React, { Fragment } from "react";

import Navbar from "./components/Navbar";
import HomePage from "./pages/Home";

const App = () => {
  return (
    <Fragment>
      <Navbar />
      <HomePage />
    </Fragment>
  );
};

export default App;
