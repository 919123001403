import React from "react";
import { HomeContainer } from "./Home.styles";
import Hero from "./components/Hero";
import Tokenomics from "./components/Tokenomics";
import Motivation from "./components/Motivation";
import Disclaimer from "./components/Disclaimer";

const HomePage = () => {
  return (
    <HomeContainer>
      <Hero />
      <Tokenomics />
      <Motivation />
      <Disclaimer />
    </HomeContainer>
  );
};

export default HomePage;
